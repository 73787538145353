import { createApp } from 'vue'
import RouterList from './router'
import App from './App.vue'
// import ContribHls from "videojs-contrib-hls"

import 'video.js/dist/video-js.css'

const app = createApp(App)

app.use(RouterList)
// app.use(ContribHls)
app.mount('#app')