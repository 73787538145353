import { createWebHistory, createRouter } from 'vue-router'

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  { path: '/', component: () => import('@/views/home.vue') },
  { path: '/home', component: () => import('@/views/home.vue') },
  { path: '/guojibowuguan',
    // component: () => import('@/views/guojibowuguan/index.vue'),
    children: [
      {
        path: 'live',
        component: () => import('@/views/guojibowuguan/live.vue'),
        meta: { title: '518博物馆日直播', keepAlive: false }
      },
      {
        path: 'meeting',
        component: () => import('@/views/guojibowuguan/meeting.vue'),
        meta: { title: '会议流程', keepAlive: false }
      },
      {
        path: 'government',
        component: () => import('@/views/guojibowuguan/government.vue'),
        meta: { title: '组织单位', keepAlive: false }
      },
    ],
  }
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  routes, // `routes: routes` 的缩写
})

router.afterEach((to)=> {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
})

export default router